import { Loader } from '@googlemaps/js-api-loader'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  styled
} from '@mui/material'
import { ErrorText } from 'components'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { type IUserValue } from '../Account'

interface IFormData {
  name: string
  portalPhone: string
  mailingAddress: string
  email: string
  mailingAddressObj?: IMailingAddress
}

export interface IMailingAddress {
  addressLine1: string
  addressLine2: string
  postal: string
  city: string
  state: string
}

export const EditInformationForm = ({
  userValue,
  onMailingAddressChange,
  onPortalPhoneChange
}: {
  userValue: IUserValue
  onMailingAddressChange: (mailingAddress: IMailingAddress) => void
  onPortalPhoneChange: (portalPhone: string) => void
}): JSX.Element => {
  const schema = yup.object().shape({
    portalPhone: yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        'Please enter a valid US phone number in the format (123) 456-7890.'
      )
      .required('Contact number is required'),
    mailingAddress: yup.string().required('Mailing address is required')
  })
  const {
    control,
    formState: { errors },
    setValue,
    watch
  } = useForm<IFormData>({
    mode: 'all',
    defaultValues: {
      name: userValue.name ?? '',
      portalPhone: userValue.mobileNumber ?? userValue.officeNumber ?? '',
      mailingAddress: userValue.mailingAddress ?? '',
      email: userValue.email ?? ''
    },
    resolver: yupResolver(schema)
  })
  const portalPhoneValue = watch('portalPhone')

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
      version: 'weekly',
      libraries: ['places']
    })

    loader
      .importLibrary('places')
      .then((places) => {
        const addressInput = document.getElementById('address-input')
        const autocomplete = new places.Autocomplete(addressInput, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components', 'formatted_address'],
          types: ['address']
        })
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace()
          const addressArray = place.address_components
            .filter((item: { types: string[] }) => ['street_number', 'route', 'locality', 'political', 'administrative_area_level_1', 'postal_code'].includes(item.types[0]))
            .map((item: { short_name: string }) => item.short_name)

          const houseNumber = addressArray[0].toUpperCase() ?? ''
          const streetName = addressArray[1].toUpperCase() ?? ''
          const city = addressArray[2].toUpperCase() ?? ''
          const state = addressArray[3].toUpperCase() ?? ''
          const postal = addressArray[4].toUpperCase() ?? ''
          const completeAddress = `${houseNumber} ${streetName}, ${city}, ${state} ${postal}`

          setValue('mailingAddress', completeAddress)

          onMailingAddressChange({
            addressLine1: `${houseNumber} ${streetName}`,
            addressLine2: '',
            postal,
            city,
            state
          })
        })
      })
      .catch((e) => { console.log(e) })
  }, [onMailingAddressChange, setValue])

  useEffect(() => {
    if (
      portalPhoneValue !== userValue.mobileNumber &&
      portalPhoneValue !== userValue.officeNumber
    ) {
      onPortalPhoneChange(portalPhoneValue)
    }
  }, [portalPhoneValue])

  return (
    <>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mb={2.5}>
              <FormLabel>NAME</FormLabel>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    fullWidth
                    type="text"
                    name={name}
                    placeholder="Name"
                    onChange={onChange}
                    value={value}
                    disabled
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={2.5}>
              <FormLabel>HOME / OFFICE NUMBER *</FormLabel>
              <Controller
                name="portalPhone"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    fullWidth
                    type="text"
                    name={name}
                    placeholder="Home / Office Number"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.portalPhone && (
                <ErrorText>{errors.portalPhone.message}</ErrorText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={2.5}>
              <FormLabel>MAILING ADDRESS *</FormLabel>
              <Controller
                name="mailingAddress"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    id="address-input"
                    fullWidth
                    type="text"
                    name={name}
                    placeholder="Mailing Address"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.mailingAddress && (
                <ErrorText>{errors.mailingAddress.message}</ErrorText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={2.5}>
              <FormLabel>EMAIL ADDRESS *</FormLabel>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value, name } }): JSX.Element => (
                  <OutlinedInput
                    fullWidth
                    type="text"
                    name={name}
                    placeholder="Email Address"
                    onChange={onChange}
                    value={value}
                    disabled
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      <Alert
        variant="filled"
        severity="error"
        sx={{
          backgroundColor: '#F7F9FB',
          color: '#5D6365',
          padding: '0',
          '& .MuiAlert-icon': {
            backgroundColor: '#C2241D',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center'
          },
          '& .MuiAlert-icon svg': {
            fill: 'white'
          },
          '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center'
          }
        }}
      >
        * Mandatory fields are required.
      </Alert>
    </>
  )
}

const FormLabel = styled(InputLabel)`
  color: #4d515f;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin-bottom: 8px;
`
